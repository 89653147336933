.top-header {
    background-color: var(--dark-theme-bg) !important;
    max-height: 56px;
    z-index: 1030;
}

.top-header .navbar-brand img {
    max-width: 210px;
}

.top-header .app-version {
    font-size: 10px;
    color: #2d8cff;
    float: right;
    line-height: normal;
    margin-left: 7px;
}

.top-header .dropdown {
    list-style-type: none;
}

.top-header .user-logout .user-name {
    font-size: 18px;
    font-weight: 700;
}

.top-header .user-logout .title-label {
    font-size: 13px;
    color: #333;
}

.top-header .user-info .user-name,
.top-header .user-info .user-id {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.top-header .user-logout .dropdown-menu {
    background: linear-gradient(168.68deg, #ffffff 1.12%, #00a3fd 100%);
    border: none;
}

.top-header .user-logout .dropdown-menu:before {
    background: #bce4ff;
}


/* Mini Sidebar CSS */
.main-wrapper {
    -ms-flex: 1;
    flex: 1;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.sidebar-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1030;
    width: 70px;
    height: 100%;
    background-color: var(--dark-theme-bg);
}

.sidebar-wrapper .btn-grid {
    height: 56px;
    background-color: var(--dark-theme-bg);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-wrapper ul li {
    width: 100%;
}

.sidebar-wrapper ul li a {
    color: var(--text-secondary);
    font-size: 1.15rem;
    min-height: 50px;
    line-height: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 5px !important;
}

.sidebar-wrapper ul li a:hover {
    color: #fff;
    background: #303a43;
}

.sidebar-wrapper ul li a>.title {
    width: 100%;
    font-size: 10px;
    margin-top: 4px;
    /* word-break: break-all; */
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

/* Start: Account Page CSS */
.sidebar-wrapper ul li a:hover {
    color: #fff;
    background: #303a43;
}

.sidebar-wrapper ul li a>i {
    line-height: 1;
}

.sidebar-wrapper ul li a>img {
    width: 20px;
    filter: invert(1);
    opacity: 0.7;
}

.sidebar-wrapper ul li a:hover img,
.sidebar-wrapper ul li a.active img {
    filter: invert(1);
    opacity: 1;
}

/* Start: Account Page CSS */
.account_page .card {
    background: var(--theme-color3);
}

/* Member Listing Page */
.member_listing_page .table-wrapper {
    background: var(--theme-color3);
}

.member_listing_page .table-wrapper table {
    color: var(--text-secondary);
}

.member_listing_page .table-wrapper table th {
    padding: 12px 15px;
}

.member_listing_page .table-wrapper table td {
    padding: 10px 15px;
}

.member_listing_page table td {
    vertical-align: middle;
}


/* Responsive CSS */
@media (max-width: 767.98px) {
    .member_listing_page table thead {
        display: none;
    }

    .member_listing_page table tr {
        display: grid;
    }

    .member_listing_page table tr:not(:last-child) {
        border-bottom: 0;
    }

    .member_listing_page table td:last-child {
        padding-top: 0;
    }
}

@media (max-width: 991.98px) {
    .top-header .navbar-brand img {
        max-width: 150px;
    }
}