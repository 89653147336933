/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    /* Topbar CSS */
    .share-link .dropdown-menu-end.caret-indicator:before {
        right: 35px;
    }

    /* Video Section CSS */
    .when-normal .video_track_container:first-child:nth-last-child(1) {
        width: 100%;
        height: 100%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(2),
    .when-normal .video_track_container:first-child:nth-last-child(2)~.video_track_container {
        width: 50%;
        height: 100%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(3),
    .when-normal .video_track_container:first-child:nth-last-child(3)~.video_track_container {
        width: 33.333%;
        height: 100%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(4),
    .when-normal .video_track_container:first-child:nth-last-child(4)~.video_track_container {
        width: 50%;
        height: 50%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(5),
    .when-normal .video_track_container:first-child:nth-last-child(5)~.video_track_container {
        width: 33.333%;
        height: 50%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(6),
    .when-normal .video_track_container:first-child:nth-last-child(6)~.video_track_container {
        width: 33.333%;
        height: 50%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(7),
    .when-normal .video_track_container:first-child:nth-last-child(7)~.video_track_container {
        width: 25%;
        height: 50%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(8),
    .when-normal .video_track_container:first-child:nth-last-child(8)~.video_track_container {
        width: 25%;
        height: 50%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(9),
    .when-normal .video_track_container:first-child:nth-last-child(9)~.video_track_container {
        width: 33.333%;
        height: 33.333%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(10),
    .when-normal .video_track_container:first-child:nth-last-child(10)~.video_track_container {
        width: 20%;
        height: 50%
    }

    .when-normal .video_track_container:first-child:nth-last-child(11),
    .when-normal .video_track_container:first-child:nth-last-child(11)~.video_track_container {
        width: 25%;
        height: 33.333%
    }

    .when-normal .video_track_container:first-child:nth-last-child(12),
    .when-normal .video_track_container:first-child:nth-last-child(12)~.video_track_container {
        width: 25%;
        height: 33.333%
    }

    .when-normal .video_track_container {
        width: 20%;
        height: 33.333%;
    }

    /* Video Section (when-shared) CSS */
    .main-section .when-shared {
        max-width: 350px;
    }

    .main-section .shared_screen_preview, .main-section .when-shared {
        background-color: var(--dark-theme-bg) !important;
    }

    /* .when-shared .video_track_container:last-child:nth-child(odd) {
        width: 100%;
    } */

    .when-shared .video_track_container:first-child:nth-last-child(1) {
        width: 100%;
        height: 25%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(2),
    .when-shared .video_track_container:first-child:nth-last-child(2)~.video_track_container {
        width: 100%;
        height: 25%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(3),
    .when-shared .video_track_container:first-child:nth-last-child(3)~.video_track_container {
        width: 100%;
        height: 25%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(4),
    .when-shared .video_track_container:first-child:nth-last-child(4)~.video_track_container {
        width: 100%;
        height: 25%;
    }

    .when-shared .video_track_container {
        width: 50%;
        height: 25%;
    }

    /* Start: Live Listing CSS */
    .live-listing-wrapper .list-item-wrapper {
        display: inline-table;
    }
    .live-listing-wrapper .list-item {
        min-width: 220px;
        max-width: 220px;
        padding: 1rem 0.625rem;
    }

    .live-listing-wrapper .list-item-report {
        /* min-width: 220px; */
        max-width: 220px;
        padding: 1rem 0.625rem;
    }

    /* Lead Description */
    .live-listing-wrapper .list-item:first-child {
        padding-left: 1rem;
    }
    .live-listing-wrapper .list-item:last-child {
        padding-right: 1rem;
    }
    .lead-description-wrapper .list-item.description {
        /* min-width: 500px;
        max-width: 500px; */
    }
    .lead-description-wrapper tbody .list-item-wrapper {
        vertical-align: middle;
    }
    .lead-description-wrapper .custom-style {
        margin-left: auto;
        margin-right: auto;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .when-shared .video_track_container {
        height: 20%;
    }
}




/* `sm` applies to x-small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {}

/* `md` applies to small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {}

/* `lg` applies to medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

    /* Header Section */
    .topbar .menu-title {
        display: none;
    }

    .topbar .share-link .dropdown-menu {
        width: 250px !important;
        max-width: 250px;
        left: auto;
        right: 0;
    }

    .topbar .clipboard-text-wrapper:before {
        left: auto;
        right: 12px;
    }


    /* Sidebar Section */
    .sidebar {
        top: 62px !important;
        height: var(--body-mob-height1) !important;
    }


    /* Video Section */
    .room_container {
        height: var(--body-mob-height1) !important;
    }

    .main-section .shared_screen_preview {
        height: 55% !important;
    }

    .main-section .when-shared {
        height: 45% !important;
    }

    .when-normal .video_track_container:first-child:nth-last-child(1) {
        width: 100%;
        height: 100%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(2),
    .when-normal .video_track_container:first-child:nth-last-child(2)~.video_track_container {
        width: 100%;
        height: 50%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(3),
    .when-normal .video_track_container:first-child:nth-last-child(3)~.video_track_container {
        width: 100%;
        height: 33.333%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(4),
    .when-normal .video_track_container:first-child:nth-last-child(4)~.video_track_container {
        width: 50%;
        height: 50%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(5),
    .when-normal .video_track_container:first-child:nth-last-child(5)~.video_track_container {
        width: 50%;
        height: 33.333%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(6),
    .when-normal .video_track_container:first-child:nth-last-child(6)~.video_track_container {
        width: 50%;
        height: 33.333%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(7),
    .when-normal .video_track_container:first-child:nth-last-child(7)~.video_track_container {
        width: 33.333%;
        height: 33.333%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(8),
    .when-normal .video_track_container:first-child:nth-last-child(8)~.video_track_container {
        width: 33.333%;
        height: 33.333%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(9),
    .when-normal .video_track_container:first-child:nth-last-child(9)~.video_track_container {
        width: 33.333%;
        height: 33.333%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(10),
    .when-normal .video_track_container:first-child:nth-last-child(10)~.video_track_container {
        width: 33.333%;
        height: 25%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(11),
    .when-normal .video_track_container:first-child:nth-last-child(11)~.video_track_container {
        width: 33.333%;
        height: 25%;
    }

    .when-normal .video_track_container:first-child:nth-last-child(12),
    .when-normal .video_track_container:first-child:nth-last-child(12)~.video_track_container {
        width: 33.333%;
        height: 25%;
    }

    .when-normal .video_track_container {
        width: 33.333%;
        height: 20%;
    }

    /* Main Video Section (When Shared) */
    .when-shared .video_track_container:first-child:nth-last-child(1) {
        width: 100%;
        height: 100%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(2),
    .when-shared .video_track_container:first-child:nth-last-child(2)~.video_track_container {
        width: 50%;
        height: 100%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(3),
    .when-shared .video_track_container:first-child:nth-last-child(3)~.video_track_container {
        width: 33.333%;
        height: 100%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(4),
    .when-shared .video_track_container:first-child:nth-last-child(4)~.video_track_container {
        width: 50%;
        height: 50%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(5),
    .when-shared .video_track_container:first-child:nth-last-child(5)~.video_track_container {
        width: 33.333%;
        height: 50%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(6),
    .when-shared .video_track_container:first-child:nth-last-child(6)~.video_track_container {
        width: 33.333%;
        height: 50%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(7),
    .when-shared .video_track_container:first-child:nth-last-child(7)~.video_track_container {
        width: 33.333%;
        height: 33.333%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(8),
    .when-shared .video_track_container:first-child:nth-last-child(8)~.video_track_container {
        width: 33.333%;
        height: 33.333%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(9),
    .when-shared .video_track_container:first-child:nth-last-child(9)~.video_track_container {
        width: 33.333%;
        height: 33.333%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(10),
    .when-shared .video_track_container:first-child:nth-last-child(10)~.video_track_container {
        width: 25%;
        height: 33.333%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(11),
    .when-shared .video_track_container:first-child:nth-last-child(11)~.video_track_container {
        width: 25%;
        height: 33.333%;
    }

    .when-shared .video_track_container:first-child:nth-last-child(12),
    .when-shared .video_track_container:first-child:nth-last-child(12)~.video_track_container {
        width: 25%;
        height: 33.333%;
    }

    .when-shared .video_track_container {
        width: 20%;
        height: 33.333%;
    }

    /* Start: Live Listing CSS */
    .live-listing-wrapper thead {
        display: none!important;
    }
    .live-listing-wrapper .list-item-wrapper {
        display: grid!important;
    }
    .live-listing-wrapper .list-item {
        width: 100%;
    }
    .live-listing-wrapper .list-item:not(:last-child) {
        padding-top: 0!important;
    }
}

/* `xl` applies to large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {}

/* `xxl` applies to x-large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {}