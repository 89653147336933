:root {
    --primary2: #0a58ca;
    --chatboat-bg1: #f5f5f5;
    --chatboat-bg2: #f0f0f0;
    --chatboat-clr1: #424242;
    --chatboat-clr2: #242424;
    --chatboat-clr3: #6c757d;
    --chatboat-clr4: #000;
}

.dark-mode {
    --chatboat-bg1: --dark-theme-bg1;
    --chatboat-clr1: #bcbcbc;
    --chatboat-clr4: #fff;
}


/* User Chat Page CSS */
.chatboat-page {
    background-color: var(--chatboat-bg1);
    color: var(--chatboat-clr2);
    height: calc(100vh - 56px);
    overflow: hidden;
}

.chatboat-page .user-chip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
}

/* Sidebar CSS */
.chatboat-wrapper aside {
    width: 300px;
    min-width: 300px;
    color: var(--chatboat-clr3);
    
}

.chatboat-wrapper .chat-header {
    background-color: var(--chatboat-bg1);
}

.chatboat-wrapper .chat-header li a {
    color: var(--chatboat-clr3);
    transition: none;
}



.chatboat-wrapper .chat-header li a.active {
    color: var(--chatboat-clr4);
}

.chatboat-page .chat-header .navbar-toggler:focus {
    box-shadow: none;
}

.chatboat-wrapper .list-head {
    min-height: 60px;
    height: 60px;
}

.chatboat-wrapper .list-head .left button {
    color: var(--chatboat-clr4);
    transition:none;
}

.chatboat-wrapper aside .list-wrap .user-card:hover,
.chatboat-wrapper aside .list-wrap .user-card.active {
    background-color: var(--bs-white);

}

.chatboat-wrapper aside .user-card:hover .dateTime,
.chatboat-wrapper aside .user-card.active .dateTime {
    display: none;
}

.chatboat-wrapper aside .user-card:hover .dropdown-option,
.chatboat-wrapper aside .user-card.active .dropdown-option {
    display: block !important;
}

.chatboat-wrapper .user-pic .user-status {
    bottom: 0;
    right: -3px;
    line-height: 0;
}

.chatboat-wrapper .user-pic .user-status span {
    font-size: 10px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    overflow: hidden;
    line-height: 1;
    display: inline-block;
    border: 1px solid var(--bs-white);
}

.chatboat-wrapper aside .user-card .user-name {
    color: var(--chatboat-clr1);
}

.chatboat-wrapper aside .user-card:hover .user-name, .chatboat-wrapper aside .user-card.active .user-name {
    color: var(--chatboat-clr2);
    opacity: 0.9;
}



/* Message Content Section CSS */
.main-chat-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.main-chat-wrapper .user-card .user-id {
    color: var(--chatboat-clr4);
}

.message-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width:100%; 
}

.message-wrap .message-outside .box-wrapper {
    max-width: 70%;
    text-align: left;
}

.message-wrap .message-outside .user-pic {
    top: 30px
}

.message-wrap .message-myside .box-wrapper {
    max-width: 70%;
    text-align: right;
    margin-left: auto;
}

.message-wrap .message-card .msg-label {
    color: var(--chatboat-clr3);
}

.message-wrap .message-card .msg {
    background-color: var(--bs-white);
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .1);
    padding: 10px 5px;
    margin-bottom: 2px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
}

.message-wrap .message-card .file-type {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    margin: 0.25rem;
    min-width: 200px;
    max-width: 600px;
}

.message-card .file-type button:hover i,
.message-card .file-type button:active i {
    color: var(--bs-black);
}

.message-wrap .message-myside .msg {
    background-color: rgba(13, 110, 253, 0.18);
    color: var(--chatboat-clr1);
}
.message-wrap .chatwindow-wrapper {
    color: var(--chatboat-clr4);
}
/* Custom scrollbar for Chat page only */

aside .list-wrap::-webkit-scrollbar,
.message-wrap::-webkit-scrollbar {
    background: #d8d8d8;
    border-radius: 10px;
    width: 5px;
}
 
aside .list-wrap::-webkit-scrollbar-thumb,
.message-wrap::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}
 
aside .list-wrap::-webkit-scrollbar-track,
.message-wrap::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.message-wrap::-webkit-scrollbar {
    width: 5px;
    background: #fff;
    border-radius: 10px;
  }

    /* Track */
    .message-wrap::-webkit-scrollbar-track {
        /* box-shadow: inset 0 0 5px grey; */
        border-radius: 10px;
      }
      
      /* Handle */
      .message-wrap::-webkit-scrollbar-thumb {
        background: gray;
        border-radius: 10px;
      }

/* Hide scrollbar for IE, Edge and Firefox */
/* .message-wrap {
    -ms-overflow-style: none;
   
    scrollbar-width: none;
   
} */

/* Message footer CSS */
.message-footer button {
    width: 30px;
}

.message-footer input {
    font-size: .875rem;
    height: 42px;
}

.message-footer .icon-wrapper button, .message-footer .icon-wrapper label {
    color: #6c757d;
}


/* New chat search header css */
.newchat-wrapper input {
    font-size: var(--bs-font-size-sm);
}

.newchat-wrapper input:focus {
    box-shadow: none;
}

.newchat-wrapper ul .dropdown-item:focus {
    background-color: #d9e6f3;
}

/* Add participants code */
.add-participants {
    max-width: 410px;
    width: 100%;
    top: 60px;
    z-index: 111;
}

.add-participants .chip-img {
    background-color: var(--theme-blue1);
}

.add-participants .chip-img {
    width: 30px;
    min-width: 30px;
    height: 30px;
}

.add-participants .custom-select__multi-value__label .chip-img {
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
    font-size: 10px;
}

.add-participants .custom-select__multi-value__label .user_role {
    display: none;
}

.add-participants button {
    min-width: 90px;
}

/* Group Participants */
.group-participants .remove_icon {
    opacity: 0;
    visibility: hidden;
}

.group-participants .user-card:hover,
.group-participants .user-card:active {
    background-color: var(--chatboat-bg2);
}

.group-participants .user-card:hover .remove_icon {
    opacity: 1;
    visibility: visible;
}

/* Incoming Call Popup CSS */
.incoming-call-modal {
    width: 100%;
    max-width: 350px;
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    z-index: 1055;
}

.incoming-call-modal .user-bg {
    height: 250px;
    width: 100%;
}

.incoming-call-modal .user-bg img {
    height: 100%;
    filter: blur(3px);
}

.incoming-call-modal .user-shortname {
    color: #2c434b;
}

.incoming-call-modal .user-bg .user-shortname {
    font-size: 250px;
    filter: blur(7px);
}

.incoming-call-modal .chip-img {
    background-color: #d8e4ea;
}

.incoming-call-modal .action-wrapper button {
    width: 40px;
    height: 40px;
}

.incoming-call-modal .action-wrapper img {
    width: 24px;
}

/* Incoming Message Card CSS */
.chat-message-notify {
    width: 100%;
    max-width: 350px;
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    z-index: 1055;
}

.chat-message-notify .reply-block {
    background-color: var(--primary2)
}

.chat-message-notify .message-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

/* Edit Option CSS */
.MessageHoverOption {
    transition: all .2s;
}

/* .MessageHoverOption {
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
}

.box-wrapper .msg:hover .MessageHoverOption,
.box-wrapper .msg:hover .MessageHoverOption {
    opacity: 1;
    visibility: visible;
} */

.MessageHoverOption .HoverOption {
    height: 35px;
}

.HoverOption button img {
    width: 20px;
}

.MessageHoverOption .HoverOption .dropdown-menu li i,
aside .user-card .dropdown-menu li i {
    display: inline-block;
    width: 20px;
}

.MessageHoverOption .HoverOption button:hover {
    color: var(--bs-primary);
}

/* When dark mode enabled */
.dark-mode .btn-group .btn {
    background: #0d6efd;
    color: #fff!important;
    border-color: var(--chatboat-clr1)!important;
}
/* .dark-mode button [title="Light mode"]{
    display: none;
}

.dark-mode button [title="Dark mode"]{
    display: block!important;
} */

.dark-mode aside .user-card, .dark-mode .list-head, .dark-mode aside {
    border-color: #2f2f2f!important;
}

@media (max-width: 767.98px) {
    .chatboat-page aside {
        width: 100%;
        max-width: 100%;
        height: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        background-color: var(--bs-white);
        box-shadow: 0 5px 25px -5px rgba(0, 0, 0, 1);
        z-index: 1030;
        transition: all .5s;
    }

    .dark-mode .chatboat-page aside {
        background-color: var(--bs-black);
    }

    .chatboat-page aside.active {
        height: 60vh !important;
    }
}

@media (max-width: 991.98px) {
    .chatboat-page .chat-header .navbar-collapse {
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0px;
        background-color: rgb(206 206 206);
        border-bottom: 1px solid var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        padding: 0 10px;
        z-index: 11;
    }

    .chatboat-page .message-outside .box-wrapper {
        max-width: 100%;
    }

    .chatboat-page .message-myside .box-wrapper {
        max-width: calc(100% - 38px);
    }

    .dark-mode .chatboat-page .chat-header .navbar-collapse {
        background-color: var(--dark-theme-bg) !important;
    }
    .dark-mode .chatboat-page .chat-header .navbar-toggler {
        filter: invert(100);
    }
}

/*
* chat loader left side list
*/
.main-item {
    /* padding: 10px; */
    background-color: #fff;
    width: 100%;
    height: 100%;
  }
  
  .background-masker {
    background-color: #fff;
    position: absolute;
  }
  
  .btn-divide-left {
    top: 0;
    left: 25%;
    height: 100%;
    width: 5%;
  }
  
  @keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }
.animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 100%;
    position: relative;
  }
  
  .static-background {
    background-color: #f6f7f8;
    background-size: 800px 104px;
    height: 70px;
    position: relative;
    margin-bottom: 20px;
  }
  
  .shared-dom {
    width: 800px;
    height: 110px;
  }
  .sub-rect {
    border-radius: 100%;
    width: 70px;
    height: 70px;
    float: left;
    margin: 20px 20px 20px 0;
  }
  .pure-background {
    background-color: #eee;
  }
  
  .css-dom:empty {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: 0 10px 45px rgba(0, 0, 0, .2);
    background-repeat: no-repeat;
    
    background-image:
      radial-gradient(circle 16px, lightgray 99%, transparent 0),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(lightgray, lightgray),
      linear-gradient(#fff, #fff); 
    
    background-size:
      32px 32px,
      200px 32px,
      180px 32px,
      230px 16px,
      100% 40px,
      280px 100%;
    
    background-position:
      24px 30px,
      66px 30px,
      24px 90px,
      24px 142px,
      0 180px,
      0 0;
  }


  :root {
    --primary2: #0a58ca;
}

/*
* loader ended
*/