.attendance-page .list-item-cover{
    background-color: var(--dark-theme-bg);
    color: var(--text-secondary);
}

.attendance-page .list-item:last-child {
    border: none!important;
}

.attendance-page .day-status .badge {
    font-size: 14px;
}


@media (max-width: 767px) {
    .attendance-page .list-item {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 768px) {
    .attendance-page .list-item {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}



