/* .container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.participant {
    margin-bottom: 5px;
    margin-right: 5px;
} */
.participant div {
    text-align: center;
}
.participant div video {
    background-color: #ccc;
    border: 1px solid black;
}
.participant div video:not(.participantZoomed) {
    width: 240px;
    height: 180px;
}
.participant .label {
    background-color: #ddd;
}
.participantZoomed {
    position: absolute !important;
    top: 61px;
    left: 0px;
    width: 100% !important;
    height: 92% !important;
    z-index: 9;
}
.participantHidden {
    display: none;
}
.cursor{
    cursor: nw-resize;
}

.hide{
    display: none !important;
  }

  /* .show{
    display: block !important;
  } */

