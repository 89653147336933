.room_not_found_paragraph {
  color: red;
}

.loading_overlay_container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 82, 201, 0.7);
}

.loading_overlay_loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #0052c9; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.checkbox_container {
  display: flex;
  margin-top: 5px;
  align-items: center;
}

.checkbox_connection {
  width: 20px;
  height: 20px;
  background: #848484;
  border: 1px solid grey;
  border-radius: 3px;
  backdrop-filter: blur(80px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
}

.checkbox_image {
  max-width: 100%;
  max-height: 100%;
}

.join_room_buttons_container button {
  min-width: 100px;
  font-weight: 500;
}