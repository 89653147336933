/* Video Background Preview */
.topbar .camera-preview:hover .video-preview-wrapper {
    display: block;
}

.video-preview-wrapper {
    background-color: var(--dark-theme-bg);
}

.video-preview-wrapper .preview-label {
    color: var(--text-primary);
}

.video-preview-wrapper .video-preview video {
    height: 170px;
    display: block;
}

.video-effect-wrapper .effect-item {
    color: var(--text-secondary);
}
.video-preview-wrapper .effect-item:focus .video-preview-wrapper{
    display: none!important;
}

/* Sidebar CSS */
.bg_setting_wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    align-content: flex-start;
    height: 100%;
    overflow: auto;
}
.bg_setting_wrapper .bg_item{
    height: 80px;
    position: relative;
}
.bg_setting_wrapper .bg_item img{
    width: 100%;
    height: 100%;
    display: block;
}
.bg_setting_wrapper .bg_item.bg_selected_box{
    border-color: var(--bs-warning)!important;
}
.bg_setting_wrapper .selectedTick{
    position: absolute;
    background-color: var(--bs-warning);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    font-size: 18px !important;
    top: 8px;
    left: 8px;
    color: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bg_setting_preview img, .bg_setting_preview video{
    height: 150px;
    display: block;
}
.bg_bottom_cover {
    background-color: var(--dark-theme-bg2);
}
.bg_bottom_cover .label-text {
    color: var(--text-secondary);
}
.appy-btn{
    display:none;
}